import React, { useState, useEffect } from 'react';
import HighCharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from 'dayjs';
import { API_ROOT } from "../ApiConfig/apiConfig";
import axios from "axios";
import { faLessThanEqual, faYenSign } from '@fortawesome/free-solid-svg-icons';


const apiOptions = { headers: { Authorization: `Bearer ${window.token}` } };

let rate=[],xAxis=[];

function HCModal() 
{    
    const [callApi, setCallApi] = useState(false);
    const [options, setOptions] = useState({
        chart:{
            type:'area',
            height: 300,
            width: 400,
            backgroundColor: '#F4F4F4'
        },
        title: {
            text: 'Fed Funds Future Curve',
            style: {
                color: '#000F0C',
                fontSize: '14pt',
                fontFamily:'Arial'
            }
        },
        tooltip: {
            formatter: function () {
                return '<b>' + HighCharts.dateFormat('%B %Y',this.x) + '</b></br> <b>Rate: </b>'+ this.y.toFixed(3) + '%';
            },
            shared: true
            
          },
          legend: {
            enabled: false
        },
        plotOptions: {        
            series: {         
        
                marker: {
                    enabled: false,
                },
                fillColor: {
                    linearGradient: [0, 0, 0, 130],
                    stops: [
                        [0, '#3174D8'],
                        [1, '#FFFFFF']
                    ]
                }
            }
        },
        series: [
            {
                xAxis: 0,
                yAxis: 0,
            name:"",
            threshold:null,
            data: []
            }
        ],
        yAxis: {
            title: {
                text: null
            },
            labels:{
                formatter: function() {
                    return this.value.toFixed(2) + '%';
                }
            },
            tickInterval:1,
            gridLineColor: '#3174D8',
            gridLineWidth: 0.25,
            lineWidth: 0,
            min: (function() {
                return 0;
            })()
        },
        xAxis: {
            type: 'date',
            labels:{
                rotation: -45,
            formatter: function() {
                return dayjs(this.value).isValid()
                ? dayjs(this.value).format("MMM YY") : '';
            },
            style: {
                color: '#6B6B6B',
                fontSize: '10pt',
                fontFamily:'Arial'
            },        
            lineColor: '#6B6B6B',
            lineWidth: 0
            },
            categories: []
            
        }
    });
    const apiOptions = { headers: { Authorization: `Bearer ${window.token}` } };
    const getFedFundsDataSource = async () => {
        axios
      .get(
        `${API_ROOT.glServiceEndpoint}/api/Rates/GetFederalFunds`,
        apiOptions
      )
      .then((response) => {
        let rateArray=[],yearArray=[];
        
        response.data.forEach((fFund, index) => {
            rateArray[index] = fFund.rate;
            yearArray[index] = new Date(fFund.symbol.year, fFund.symbol.month);
          });
          setOptions({ series: [{ data: rateArray }] ,xAxis: {categories: yearArray}});
      })
      .catch((error) => {
        console.log(error);
      });
    };

      useEffect(() => {        
        getFedFundsDataSource();
      }, [callApi]);

      

        return (
        <div>
            <HighchartsReact highcharts={HighCharts}  options={options}/>
        </div>);
}

export default HCModal;