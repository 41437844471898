import axios from "axios";
import React, { useState, useEffect } from "react";
import { API_ROOT } from "../../ApiConfig/apiConfig";
import "./customReport.css";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { LoadingSpinner } from "@deluxe/unify-loading-spinner";
import _ from "lodash";
import * as ReportUtils from "./customReportUtilities";
import ReportAccounts from './customReportAccount';

function CustomReport({
  reportParameters,
  suppressZeros,
  addFooter,
  setLoadingState,
}) {
  const apiOptions = { headers: { Authorization: `Bearer ${window.token}` } };
  const [report, setReport] = useState(null);
  const [nodes, setNodes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDetailedReport, setIsDetailedReport] = useState(false);
  const [showReport, setShowReport] = useState(true);
  const [showAccount, setShowAccount] = useState(false);
  const [accountParams, setAccountParams] = useState(null);
  const [groupId, setGroupId] = useState(null);
 
  let foundNode = false;
  const [expandedKeys, setExpandedKeys] = useState({});

  const [columns, setColumns] = useState(null);

  const extractFooter = (paramsWithFooter) => {
    let footerParamIndex = paramsWithFooter.lastIndexOf("&");
    return paramsWithFooter.substring(0, footerParamIndex);
  };
  const [reportParams, setReportParams] = useState(null);

  const getUpdatedNodes = (childNodes, nodeKey, updatedNode, expanded) => {
    let _childNodes = childNodes.map((childNode) => {
      if (!foundNode) {
        if (childNode.key === nodeKey) {
          childNode = updatedNode;
          childNode.expanded = expanded;
          foundNode = true;
        } else if (
          childNode.children &&
          childNode.children.length > 0 &&
          !foundNode
        ) {
          childNode.children = getUpdatedNodes(
            childNode.children,
            nodeKey,
            updatedNode,
            expanded
          );
        }
      }
      return childNode;
    });
    return _childNodes;
  };
  const onExpand = (e) => {
    if (e.node.children && e.node.children.length > 0) {
      let expandedNode = _.cloneDeep(e.node);
      let totNode = _.cloneDeep(expandedNode);
      expandedNode.data = { name: e.node.data.name };
      totNode.id = "tot" + totNode.id;
      totNode.key = "tot" + totNode.key;
      totNode.lineItemCategory = 2;
      totNode.expanded = true;
      totNode.className = "bd-group-total-row";
      delete totNode.children;
      expandedNode.children.push(totNode);
      let _nodes = nodes.map((node) => {
        if (!foundNode) {
          if (node.key === e.node.key) {
            node = expandedNode;
            node.expanded = true;
            foundNode = true;
          } else if (node.children && node.children.length > 0 && !foundNode) {
            node.children = getUpdatedNodes(
              node.children,
              e.node.key,
              expandedNode,
              true
            );
          }
        }
        return node;
      });
      foundNode = false;
      let _expandedKeys = { ...expandedKeys };
      _expandedKeys[e.node.key] = true;
      setExpandedKeys(_expandedKeys);
      setNodes(_nodes);
    }
  };

  const onCollapse = (e) => {
    if (e.node.children && e.node.children.length > 0) {
      let collapsedNode = _.cloneDeep(e.node);
      let nodeData = collapsedNode.children.pop();
      delete nodeData.className;
      collapsedNode.data = nodeData.data;
      let _nodes = nodes.map((node) => {
        if (!foundNode) {
          if (node.key === e.node.key) {
            node = collapsedNode;
            node.expanded = false;
            foundNode = true;
          } else if (node.children && node.children.length > 0 && !foundNode) {
            node.children = getUpdatedNodes(
              node.children,
              e.node.key,
              collapsedNode,
              false
            );
          }
        }
        return node;
      });
      foundNode = false;
      let _expandedKeys = { ...expandedKeys };
      if (_expandedKeys[e.node.key]) delete _expandedKeys[e.node.key];
      setExpandedKeys(_expandedKeys);
      setNodes(_nodes);
    }
  };

  const getAccountQuery = (reportParams) => {
    return reportParams.includes("&summaryOnly=true") ? reportParams.replace("&summaryOnly=true", "") : reportParams.replace("&summaryOnly=false", "");
  };

  const rowClicked = (e) => {
    if (e.node.isClickable) {
      setShowReport(false);
      setShowAccount(true);
      setAccountParams(reportParams ? getAccountQuery(reportParams) : '');
      setGroupId(e.node.lineItemNumber);
    } else {
      return false;
    }
  };

  const expandAll = (reportNodes) => {
    let _expandedKeys = {};
    for (let node of reportNodes) {
      expandNode(node, _expandedKeys);
    }
    setExpandedKeys(_expandedKeys);
  };

  const expandNode = (node, _expandedKeys) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;
      for (let child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  const setReportProperties = () => {
    setReportParams(extractFooter(reportParameters));
    setIsDetailedReport(reportParameters.includes("summaryOnly=false"));
  };

  useEffect(() => {
    setShowReport(true);
    setReportProperties();
    const fetchReportData = async () => {
      try {
        let params = extractFooter(reportParameters);
        const result = await axios.get(
          `${API_ROOT.glServiceEndpoint}/api/report?${params}`,
          apiOptions
        );
        let reportData = result.data;
        const metadata = reportData.headerMetadata.periodMetadata;
        reportData.isGroupView = true;
        reportData.reportHeader = reportData.lineItems[0].lineItemData;
        reportData.reportHeader.periodSourceHeader = `${metadata[0].periodTypeName} - ${metadata[0].dataSourceName}`;
        reportData.suppressZeros = suppressZeros;
        reportData.addFooter = addFooter;
        setReport(reportData);
        let colsData = ReportUtils.buildColumns(
          reportData.reportHeader.periodValues,
          metadata
        );
        setColumns(colsData);
        let nodesData = ReportUtils.loadNodes(
          reportData.lineItems,
          colsData,
          true,
          params.includes("summaryOnly=false")
        );
        if (params.includes("summaryOnly=false")) {
          expandAll(nodesData);
        }
        setNodes(nodesData);
        setLoading(false);
        setLoadingState(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchReportData();
    return () => {
      setReport(null);
      setExpandedKeys({});
    };
  }, [reportParameters]);
  if (report && columns && columns.length > 0 && nodes && nodes.length > 0 && showReport) {
    return (
      <>
         <div className="report-title-container">
          <h1 data-testid="report-custom-title" className="app-title-h1">{report.title}</h1>
          <span data-testid="report-custom-subtitle" className="report-custom-subtitle">{report.subtitle}</span>
        </div> 
        <div className="card">
          <div
            data-testid="report-custom-period-source"
            className="period-source-header"
          >
          </div>
          <TreeTable
            value={nodes}
            scrollable
            scrollHeight="65vh"
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            onCollapse={onCollapse}
            footer={
              report.suppressZeros && report.addFooter
                ? ReportUtils.footer
                : null
            }
            frozenWidth="288px"
            onRowClick={rowClicked}
            tableClassName="tableStyle"
          >
            {columns.map((col, i) => (
              <Column
                key={col.field}
                field={col.field}
                header={col.header}
                frozen={col.frozen}
                expander={col.expander}
                align={col.align}
                body={col.body}
                className="bd-table-cell"
                style={{ textAlign: col.align, width: col.width }}
              />
            ))}
          </TreeTable>
        </div>
        <div></div>
      </>
    );
  }
  else if (report && !showReport && showAccount && accountParams){
    return <ReportAccounts 
              accountParams={accountParams} 
              groupId={groupId} 
              periodSourceHeader={report.reportHeader.periodSourceHeader} 
              suppressZeros={report.suppressZeros} 
              addFooter={report.addFooter}
              periodMetadata={report.headerMetadata.periodMetadata}
              setShowReport = {setShowReport}
              setShowAccount = {setShowAccount}
              />

  } else {
    return <LoadingSpinner isActive={loading} />;
  }
}

export default CustomReport;
